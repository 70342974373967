import Validate from '@/common/validate/Validate.vue';
import quoteService from '@/services/quoteService';
import helperService from '@/services/helperService';

export default {
	name: 'Quote',
	components: {
		'validate-field': Validate,
	},
	data: function() {
		return {
			currentYear: new Date().getFullYear(),
			styleObject: {},
			showModal: false,
			modalTitle: '',
			current_step: '',
			auto_interest: false,
			year: '',
			isVisible: {
				first_name: true,
				last_name: false,
				phone: false,
				email: false,
				calculate: false,
			},
			validate: {
				first_name: {
					valid: false,
					touched: false,
					focus: false,
					loading: false,
				},
				last_name: {
					valid: false,
					touched: false,
					focus: false,
					loading: false,
				},
				phone: {
					valid: false,
					touched: false,
					focus: false,
					loading: false,
				},
				email: {
					valid: false,
					touched: false,
					focus: false,
					loading: false,
				},
				year: {
					valid: false,
					touched: false,
					focus: false,
					loading: false,
				},
			},
			user: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
			},
			loading: false,
			year_error: false,
			info_msg: '',
			move_quote_submitted: false,
			isCompleted: false,
			submitError: '',
			submitButtonText: '',
		};
	},
	created: function() {
		this.modalTitle = 'Shipper Information';
		this.current_step = 'info';

		helperService.init();

		if (helperService.isLocalStorageSupported()) {
			this.move_quote_submitted = localStorage.getItem(
				'move_quote_submitted'
			);
			this.move_quote_submitted = parseInt(this.move_quote_submitted);

			if (this.move_quote_submitted) {
				this.current_step = 'select_year';
			}
		}

		const delay = this.move_quote_submitted ? 0 : 4000;

		setTimeout(() => {
			this.showModal = true;
		}, delay);

		this.checkQuoteData();
		this.submitButtonText = 'Continue';
	},
	beforeRouteEnter: function(to, from, next) {
		if (from.name || helperService.isValidLandingState(5)) {
			next(true);
		} else {
			next('/');
		}
	},
	beforeRouteLeave: function(to, from, next) {
		if (this.current_step === 'auto_interest' && to.name !== 'Thanks') {
			next(false);
		} else if (this.current_step === 'select_year' && to.name !== 'Make') {
			next(false);
			this.current_step = 'auto_interest';
		} else {
			next(true);
		}
	},
	computed: {
		isFormValid: function() {
			let isValid = true;

			for (const key in this.validate) {
				if (key !== 'year') {
					if (!this.validate[key].valid) {
						isValid = false;
					}
				}
			}

			this.isCompleted = isValid;

			return isValid;
		},
	},
	mounted: function() {
		helperService.updateProgress(5);
		setTimeout(() => {
			this.updateBackground();
		}, 200);

		if (window.analytics) {
			window.analytics.page(
				'Info',
				{ type: 'page', path: 'Info' },
				{ integrations: { AdWords: false } }
			);
		}
	},
	methods: {
		updateBackground: function() {
			const url = helperService.getQuoteBg();

			this.styleObject = {
				'background-image': 'url(' + url + ')',
			};
		},
		checkQuoteData: function() {
			const quote = quoteService.getQuote();

			this.size = quote.size_type || 'apartment';

			if (quote) {
				this.first_name = quote.first_name || '';
				this.last_name = quote.last_name || '';
				this.email = quote.email || '';
				this.phone = quote.phone || '';
				this.year = quote.year || '';

				if (quote.first_name && quote.first_name.length) {
					this.validate.first_name = {
						valid: true,
						touched: true,
						focus: false,
						loading: false,
					};
				}

				if (quote.last_name && quote.last_name.length) {
					this.validate.last_name = {
						valid: true,
						touched: true,
						focus: false,
						loading: false,
					};
				}

				if (quote.email && quote.email.length) {
					this.validate.email = {
						valid: true,
						touched: true,
						focus: false,
						loading: false,
					};
				}

				if (quote.phone && quote.phone.length) {
					this.validate.phone = {
						valid: true,
						touched: true,
						focus: false,
						loading: false,
					};
				}

				if (quote.year && quote.year.length) {
					this.validate.year = {
						valid: true,
						touched: true,
						focus: false,
						loading: false,
					};
				}
			}
		},
		activeNextField: function(field) {
			switch (field) {
				case 'first_name':
					this.validate.last_name.focus = true;
					break;
				case 'last_name':
					this.validate.email.focus = true;
					break;
				case 'email':
					this.validate.phone.focus = true;
					break;
				case 'phone':
					//  submit the lead if form is valid
					if (this.isFormValid) {
						this.submitForm();
					}

					break;
			}
		},
		onFieldChange: function(field, value, isValid) {
			this.submitError = '';

			this.user[field] = value;
			this.$log.debug('field_change', field, value);

			this.validate[field].valid = isValid;
			this.validate[field].touched = false;

			if (this.validate.first_name.valid) {
				this.isVisible.last_name = true;
			}

			if (this.validate.last_name.valid) {
				this.isVisible.email = true;
			}

			if (this.validate.email.valid) {
				this.isVisible.phone = true;
			}

			if (this.validate.phone.valid) {
				this.isVisible.phone = true;
			}

			// Once all fields are valid, wait 0.5s before
			// submitting the form automatically, which will ensure that
			// the user doesn't have to scroll the page in order to submit.
			if (this.isFormValid) {
				this.submitButtonText = 'Calculate';
				window.setTimeout(this.submitForm, 1000);
			}

			return this.isFormValid;
		},
		submitForm: function() {
			this.$log.debug('isFormValid -> ', this.isFormValid);

			if (!this.isFormValid) {
				this.submitError
					= 'ERROR: One or more fields are empty or invalid';

				return;
			}

			this.submitError = '';

			this.loading = true;
			quoteService.setName(this.user.first_name, this.user.last_name);
			quoteService.setEmail(this.user.email);
			quoteService.setPhone(this.user.phone);

			quoteService.submitQuote('move').then(
				resp => {
					this.loading = false;
					const data = resp.data;

					if (data.status === 'success') {
						if (data.require_text_verify) {
							localStorage.setItem('tv_text_id', data.text_id);
							this.$router.push('verify');
						} else {
							this.move_quote_submitted = true;
							this.afterVehicleInterest(false);
						}
					}
				},
				err => {
					this.loading = false;
					this.$log.error(err);
				}
			);
		},
		afterMoveQuoteSubmit: function() {
			this.modalTitle = 'Processing Quote ...';
			this.current_step = 'auto_interest';
		},
		afterVehicleInterest: function(interested) {
			this.auto_interest = interested;

			if (interested) {
				this.modalTitle = 'Select Year';
				this.current_step = 'select_year';

				setTimeout(() => {
					document.getElementById('year').focus();
				}, 400);
			} else {
				if (window.analytics) {
					window.analytics.page(
						'Thank_You',
						{ type: 'page', path: 'Thank_You' },
						{ integrations: { AdWords: false } }
					);
				}

				// this.$router.push('/thanks')
				window.location.href
					= 'https://my.topmoving.com/thank-you/'
					+ quoteService.getMoveLeadToken();
			}
		},
		afterYearSelection: function() {
			if (!this.year) {
				this.info_msg = 'Please enter the year of vehicle';
				this.year_error = true;
			} else if (this.year < 1931) {
				this.info_msg = 'Year should be greater than 1931';
				this.year_error = true;
			} else if (this.year > this.currentYear) {
				this.info_msg = `Year should be less than ${this.currentYear}`;
				this.year_error = true;
			} else {
				this.year_error = false;
				this.info_msg = '';

				quoteService.setYear(this.year);
				this.$router.push('/make');
			}
		},
		hide: function(e) {
			return e.cancel();
		},
	},
};
