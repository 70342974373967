import debounce from 'debounce';
import httpService from '@/services/httpService';
import quoteService from '@/services/quoteService';

export default {
	name: 'validate',
	props: [
		'field',
		'field_text',
		'field_ph',
		'disabled',
		'makers',
		'models',
		'onChange',
		'touched',
		'focus',
		'onEnter',
	],
	data: function() {
		return {
			field_model: '',
			validate: {
				touched: false,
				valid: false,
				loading: false,
				error: '',
				value: '',
				request: 0,
				response: 0,
			},
			make_text: 'Select Make',
			model_text: 'Select Model',
			email_suggestion: '',
		};
	},
	created: function() {
		this.field_model = this.field_value;
		this.onValueChange = debounce(this.onValueChange, 500);

		if (this.field_model && this.field_model.length) {
			this.validate.value = this.field_model;
			this.validate.touched = this.validate.valid = true;
		}

		if (this.field === 'make' && this.field_model.length) {
			this.make_text = this.field_model;
		} else if (this.field === 'model' && this.field_model.length) {
			this.model_text = this.field_model;
		} else if (this.field === 'phone') {
			this.$nextTick(() => {
				this.validate.touched = false;
			});
		}
	},
	updated: function() {
		// console.log('update', this.field, this.field_model)
	},
	watch: {
		focus: function() {
			if (this.focus === true) {
				document.getElementById(this.field).focus();
				this.$log.debug(this.field, 'focus changed');
			}
		},
		touched: function() {
			this.$log.debug('touched', this.field, this.touched);

			if (this.touched === true) {
				this.validate.touched = true;
				this.buildMessage();
			}
		},
	},
	methods: {
		formatPhoneNumber: function(phone) {
			let number = phone.replace(/\D+/g, '');

			if (number.length > 10) {
				number = number.substr(0, 10);
			}

			return number;
		},
		// setSuggestedEmail: function () {
		//   this.field_model = this.email_suggestion
		//   this.onValueChange()
		// },
		validateEmail: function() {
			const re = new RegExp(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);

			if (re.test(this.field_model) || this.validate.request > 0) {
				this.validate.touched = true;
				this.validateField();
			}
			// else if (this.validate.request > 0) {
			//   this.validate.touched = true
			//   this.validate.valid = false
			//   this.validate.value = this.field_model
			//   this.$emit('loading', false)
			//   this.afterValidation()
			// }
		},
		validateYear: function() {
			const currentYear = new Date().getFullYear();

			this.validate.touched = true;
			this.validate.value = this.field_model;

			if (this.field_model > 1930 && this.field_model <= currentYear) {
				this.validate.valid = true;
			} else {
				this.validate.valid = false;
			}

			this.afterValidation();
		},
		onValueChange: function() {
			this.validate.touched = false;

			this.$log.debug('field_model', this.field_model);

			if (!this.field_model) {
				return;
			}

			this.$emit('loading', true);

			if (!this.field_model.length) {
				this.validate.valid = false;
				this.validate.touched = true;
				this.validate.value = '';
				this.$emit('loading', false);
				this.afterValidation();

				return;
			}

			if (this.validate.value === this.field_model) {
				this.validate.touched = true;
			}

			if (this.field_model && this.field_model.length) {
				if (this.field === 'email') {
					this.email_suggestion = '';
					// this.validate.value = this.field_model;

					if (
						this.field_model.length > 5
						&& this.field_model.indexOf('@') > -1
					) {
						this.validateEmail();
					} else {
						this.validate.valid = false;
						this.$emit('loading', false);
						// this.afterValidation()
					}
				} else if (this.field === 'phone') {
					this.validate.value = this.field_model;

					if (
						this.field_model.length > 15
						|| (this.validate.request > 0
							&& this.field_model.length === 16)
					) {
						this.validateField();
					} else {
						this.validate.valid = false;
						this.$emit('loading', false);
						// this.afterValidation()
					}
				} else if (this.field === 'year') {
					this.validateYear();
				} else if (this.field === 'make' || this.field === 'model') {
					this.validate.valid = true;
					this.validate.value = this.field_model;
					this.afterValidation();
				} else {
					this.validateField();
				}
			} else {
				this.validate.touched = true;
			}

			/* else {
        // this.validate[field].touched = false;
        // this.validate[field].invalid = true;
      } */
		},
		validateField: function() {
			this.validate.touched = true;

			let url = '';
			let model = this.field_model;

			if (this.field === 'phone') {
				model = this.formatPhoneNumber(model);
			}

			if (!model.length) {
				this.validate.valid = false;
				this.validate.error = 'This field is required';

				return;
			} else if (model === this.validate.value) {
				return;
			}

			if (this.field === 'first_name' || this.field === 'last_name') {
				url = `service/name-filter?query=${model}`;
			} else {
				const leadToken = quoteService.getMoveLeadToken();

				url = `valet/${this.field}/${model}?lead_token=${leadToken}`;
			}

			this.validate.value = model;
			this.validate.loading = true;
			this.validate.error = '';
			this.validate.request++;
			httpService.get(url).then(
				resp => {
					this.validate.response++;
					this.$log.debug(
						this.validate.request,
						this.validate.response
					);

					if (this.validate.request === this.validate.response) {
						this.$emit('loading', false);
						this.validate.touched = true;
						this.validate.loading = false;
						this.validate.valid
							= this.field === 'phone' ? resp.success : resp.valid;
						this.email_suggestion = resp.data
							? resp.data.did_you_mean
							: '';
						this.afterValidation();
						// this.validate[type].touched = false;
					}
				},
				err => {
					this.$emit('loading', false);
					this.validate.loading = false;
					this.validate.valid = false;
					// this.validate.touched = false;
					this.validate.response++;
					this.$log.error('validateField | ', err);
					this.validate.error = 'Server error, please try again';
				}
			);
		},
		afterValidation: function() {
			let value = this.field_model;

			if (this.field === 'phone') {
				value = this.formatPhoneNumber(this.field_model);
			}

			if (!this.validate.loading) {
				this.buildMessage();
				this.onChange(this.field, value, this.validate.valid);
			}
		},
		buildMessage: function() {
			this.validate.error = '';

			if (this.field !== 'phone') {
				if (this.validate.touched && !this.validate.valid) {
					if (this.validate.value.length) {
						// this.validate.error = 'invalid ' + this.field_text
						this.validate.error = 'Invalid ' + this.field_text;
					} else {
						// this.validate.error = this.field_text + ' is required'
						this.validate.error = this.field_text + ' required';
					}
				}
			} else if (this.validate.touched && !this.validate.valid) {
				if (!this.validate.value.length) {
					this.validate.error
						= 'Phone number is required to get your quote.';
				} else if (this.field_model.length < 16) {
					this.validate.error
						= 'A 10-digit valid phone number is required to get your quote.';
				} else if (this.field_model.length > 15) {
					this.validate.error
						= 'Invalid phone number, please enter your active mobile number.';
				}
			}
		},
		removeErrorMessage: function() {
			if (!this.validate.valid) {
				this.validate.touched = false;
				this.onChange(
					this.field,
					this.validate.value,
					this.validate.valid
				);
			}
		},
		checkValidPhone: function() {
			setTimeout(() => {
				document.getElementById(
					'phone'
				).value = document.getElementById('phone').value;
			}, 1000);

			if (this.field_model && this.field_model.length) {
				this.onValueChange();
			}
		},
	},
};
