import quoteService from './quoteService';

export default {
	total_steps: 7,
	size: '',
	shipping_bg: '',
	delivery_bg: '',
	quote_bg: '',
	update_quote_bg: true,
	init: function() {
		const quote = quoteService.getQuote();

		if (quote && quote.size_type && this.size !== quote.size_type) {
			this.size = quote.size_type;
			this.updateQuoteBg();
		}
	},
	isValidLandingState: function(step) {
		if (this.isLocalStorageSupported()) {
			const completedSteps = localStorage.getItem('completed_steps');

			if (step - 1 > parseInt(completedSteps)) {
				return false;
			}
		}

		return true;
	},
	updateProgress: function(step) {
		let updateStep = step;

		if (this.isLocalStorageSupported()) {
			this.updateProgressInStorage(updateStep);
		}

		if (updateStep >= 5 && updateStep <= 7) {
			updateStep = 0;
			this.update_quote_bg = true;
		}

		if (this.update_quote_bg) {
			this.updateQuoteBg();
		}

		const customEvent = new CustomEvent('progress-update', {
			detail: updateStep,
		});

		window.dispatchEvent(customEvent);
	},
	updateProgressInStorage: function(step) {
		const completedSteps = localStorage.getItem('completed_steps');

		if (!completedSteps || step > parseInt(completedSteps)) {
			localStorage.setItem('completed_steps', step - 1);
		}
	},
	getProgress: function(step) {
		const progress = (step * 100) / this.total_steps;

		return progress;
	},
	changeStep: function(step) {
		if (this.allowedStateChange(step)) {
			const route = {};

			switch (step) {
				case 1:
					route.path = '/';
					break;
				case 2:
					route.path = '/size';
					break;
				case 3:
					route.path = '/shipping';
					break;
				case 4:
					route.path = '/delivery';
					break;
				case 5:
					route.path = '/quote';
					route.data = {
						step: step,
						current_step: 'name',
					};
					break;
				case 6:
					route.path = '/quote';
					route.data = {
						step: step,
						current_step: 'email',
					};
					break;
				case 7:
					route.path = '/quote';
					route.data = {
						step: step,
						current_step: 'phone',
					};
					break;
			}

			return route;
		}

		return null;
	},
	allowedStateChange: function(step) {
		const completedSteps = localStorage.getItem('completed_steps') || 0;

		if (!completedSteps || step > parseInt(completedSteps)) {
			return false;
		}

		return true;
	},
	updateSize: function() {
		this.init();
	},
	updateQuoteBg: function() {
		let image = '';

		if (this.size && this.size.length) {
			switch (this.size) {
				case 'apartment':
					image = require('@/assets/quote-apt.gif');
					break;
				case 'house':
					image = require('@/assets/quote.gif');
					break;
				case 'office':
					image = require('@/assets/quote-office.gif');
					break;
			}

			this.update_quote_bg = false;
			this.quote_bg = image + '?x=' + Date.now();

			const ele = document.getElementById('hidden_gif');

			ele.setAttribute('src', this.quote_bg);
		}
	},
	getShippingBg: function() {
		return this.shipping_bg;
	},
	getDeliveryBg: function() {
		return this.delivery_bg;
	},
	getQuoteBg: function() {
		return this.quote_bg;
	},
	isLocalStorageSupported: function() {
		if (navigator.cookieEnabled) {
			const testKey = 'test';
			const storage = window.localStorage || null;

			try {
				storage.setItem(testKey, '1');
				storage.removeItem(testKey);

				return true;
			} catch (error) {
				window.console.error(error);

				return false;
			}
		}

		return false;
	},
	// sendMixpanelData: function (event,params) {
	//   mixpanel.track(event,params);
	// },
	getQueryParams: function() {
		const regex = /[?&]([^=#]+)=([^&#]*)/g;
		const url = window.location.href;
		const params = {};
		let match = [];

		while ((match = regex.exec(url))) {
			params[match[1]] = match[2];
		}

		return params;
	},
};
